import React, {useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import * as Helper from "../form/form_inscription_helper";
import {Form} from "react-bootstrap";
import CustomFieldText from "../form/CustomFieldText";
import * as Pattern from "../form/form_pattern_helper";

const PackCelebrationLogin = (props) => {

    const {register, handleSubmit, errors, clearError, setError, setValue} = useForm({validateCriteriaMode: "all"});
    const submitRef = useRef(null);

    //form validation
    const onSubmit = data => {

        submitRef.current.setAttribute("disabled", "disabled");

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));

        fetch(process.env.REACT_APP_API_URL + '/submit_celebration_login.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    props.setLogin(true,data.code,data.mail);
                } else {
                    alert(json.error);
                    submitRef.current.removeAttribute("disabled");
                }

            });

    };

    return(
        <div id={"pack_celebrationStrate1"} className={'strate'}>
            <h1>Pack célébration</h1>
            <div className={"subtitle"}>
                IL NE RESTE PLUS QU’UNE ÉTAPE AVANT D’ACCéDER<br className={"d-none d-lg-block"}/> À VOTRE PACK CÉLÉBRATION !
            </div>

            <div className={"form_wrapper"}>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <div id={"form_content_wrapper"}>

                        <CustomFieldText name="mail" label="Adresse e-mail*"
                                         register={
                                             register({
                                                 required: true,
                                                 pattern: Pattern.emailPattern,
                                                 validate: {
                                                     domain: (value) => Helper.checkMailDomain(value)
                                                     /*confirm : checkMailConfirm*/
                                                 }
                                             })}
                                         error={[
                                             errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                             errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                             errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                             errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                         ]}/>

                        <CustomFieldText name={"code"} label={"Code pack Célébration*"}
                                         register={register({required: true})}
                                         error={[errors.code && errors.code.type === "required" && Helper.EmptyErrorMessages.code_unique]}/>
                    </div>
                    <div className="text-center">
                        <div className={"error text-center mb-3"} id={"global_error"}>
                            {/*error*/}
                        </div>
                        <button className={"cta yellow_pink big"} id={"submit_cta"} ref={submitRef}>Je valide</button>
                    </div>

                </Form>
            </div>
        </div>
    );
};

const PackCelebrationChoice = (props) => {

    const selectDotation = (dotation) => {

        let formData = new FormData();
        formData.append('form', JSON.stringify({
            "dotation" : dotation,
            "mail" : props.mail,
            "code" : props.code
        }));

        fetch(process.env.REACT_APP_API_URL + '/submit_celebration_dotation.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {


                } else {
                    alert(json.error);
                }

            });
    }

    return(
        <div id={"pack_celebrationStrate2"} className={'strate'}>
            <h1>Pack célébration</h1>
            <div className={"subtitle"}>
                <span>C’EST LE MOMENT DE FAIRE LA FETE !</span>
                Préparez votre soirée en demandant les<br className={"d-none d-lg-block"}/> codes des 3 offres digitales ci-dessous
            </div>

            <div className={"offer_wrapper"} id={"first_offer_wrapper"}>

                <div className={"offer_item"} id={"offer_item_1"}>
                    <div className={"description"}>
                        <b>Que serait une fête sans musique&nbsp;?</b> Dansez au rythme de la playlist estivale Deezer x BNP Paribas de 50 titres !
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("deezer")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>

                <div className={"offer_item"} id={"offer_item_2"}>
                    <div className={"description"}>
                        <b>Vous ne direz pas non à une pizza ?</b> Mangez avec vos amis ou en famille grâce au bon de 15€ Uber Eats !
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("ubereats")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>

                <div className={"offer_item"} id={"offer_item_3"}>
                    <div className={"description"}>
                        <b>Immortalisez votre soirée !</b> Imprimez vos photos grâce à votre code cadeau Cheerz !
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("cheerz")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>
            </div>

            <div className={"separator"}>
                1 offre à choisir parmi les suivantes :
            </div>

            <div className={"offer_wrapper"} id={"second_offer_wrapper"}>

                <div className={"offer_item"} id={"offer_item_4"}>
                    <div className={"description"}>
                        <b>Le karaoké n’a jamais été aussi fun qu’avec Yokee !</b> Pendant un mois, vous pourrez chanter en utilisant des effets spéciaux audio et vidéo.
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("yokee")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>

                <div className={"offer_item"} id={"offer_item_5"}>
                    <input type={"hidden"} value={"qello"}/>
                    <div className={"description"}>
                        <b>Vivre un concert depuis le confort de chez soi ?</b> C’est possible grâce à Qello ! Pendant un mois, vous pourrez assister aux concerts de votre choix.
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("qello")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>

                <div className={"offer_item"} id={"offer_item_6"}>
                    <input type={"hidden"} value={"rakuten"}/>
                    <div className={"description"}>
                        <b>Une séance de cinéma entre amis ou en famille vous intéresse ?</b> RakutenTV propose plus de 7000 films réalisés par les plus grands studios.
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("rakuten")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>

                <div className={"offer_item"} id={"offer_item_7"}>
                    <input type={"hidden"} value={"escape"}/>
                    <div className={"description"}>
                        <b>Vivez une aventure pleine de rebondissements</b> et partagez des moments en famille ou entre amis avec Escape Hunt, l’escape game en ligne.
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("escape")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>

                <div className={"offer_item"} id={"offer_item_8"}>
                    <input type={"hidden"} value={"stingray"}/>
                    <div className={"description"}>
                        <b>Choisissez l’ambiance que vous souhaitez</b> et écoutez la playlist de votre choix pendant 1 mois grâce à Stingray Musique !
                    </div>
                    <a href={"#0"} className={"cta yellow_pink very_small"} onClick={() => selectDotation("stingray")}>Envoyer le code</a>
                    <div className={"logo"}/>
                    <div className={"mention"}>Vous avez déjà demandé ce code mais vous ne savez plus où il est ? Cliquez à nouveau sur «envoyer le code» et vous le recevrez immédiatement.</div>

                </div>
            </div>

        </div>
    );
};

const PackCelebration = () => {

    const [login, setLogin] = useState(sessionStorage.getItem("celebration_login")??false);
    const [code, setCode] = useState(sessionStorage.getItem("celebration_code")??false);
    const [mail, setMail] = useState(sessionStorage.getItem("celebration_mail")??false);

    const handleConnection = (is_login, code, mail) => {
        setLogin(is_login);
        setCode(code);
        setMail(mail);

        sessionStorage.setItem("celebration_login","1");
        sessionStorage.setItem("celebration_code",code);
        sessionStorage.setItem("celebration_mail",mail);
    }

    return (
        <>
            {!login && <PackCelebrationLogin setLogin={handleConnection}/>}
            {login && <PackCelebrationChoice code={code} mail={mail}/>}
        </>
    );
};



export default PackCelebration;
