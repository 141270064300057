import React from 'react';
import {withRouter} from 'react-router-dom';

/*import mixpanel from 'mixpanel-browser';
import {MixpanelProvider, MixpanelConsumer} from 'react-mixpanel';*/

import Routes from './routes';

import Header from "./header"
import Footer from "./footer"

import ScrollTop from "./_scrollTop.jsx"


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.last_route = "";

        window.mixpanelhandler = {
            track : function(event, param){


                if(window.mixpanel !== undefined && process.env.REACT_APP_MIXPANEL_TOKEN != ""){
                    //mixpanel actif
                    //console.log("mixpanel dispo");

                    if(!this.init){
                        window.mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {api_host: process.env.REACT_APP_MIXPANEL_PROXY});
                        window.mixpanel.register({
                            "Country" : "France",
                            "Job ID" : "FR02774"
                        });
                    }

                    window.mixpanel.track(event,param);
                }
                else{
                    //mixpanel inactif
                    //console.log("mixpanel pas dispo");
                    //console.log("track",event,param);
                }

                //console.log("track",event,param);
                return true;
            },
            init : false
        }
    }

    render() {

        let route = this.props.location.pathname.split("/")[1]
        route = route === "" ? "index" : route

        if (this.last_route !== "" && route !== this.last_route) {
            window.scrollTo(0, 0)
        }

        //envois des event page view
        switch(route){
            case "index" :         window.mixpanelhandler.track("Participation_Homepage",{"Result count" : 1}); break;
            case "les-packs" :     window.mixpanelhandler.track("Participation_Les Packs page viewed",{"Result count" : 1}); break;
            case "je-participe" :  window.mixpanelhandler.track("Participation Form  viewed",{"Result count" : 1}); break;
        }

        this.last_route = route;

        return (
            <div className="page_wrapper" id={"page_" + route + "_wrapper"}>
                <Header/>
                <div className="content_wrapper" id={"content_" + route + "_wrapper"}>
                    <Routes/>
                </div>
                <Footer/>
                <ScrollTop/>
            </div>
        );

    }
}

export default withRouter(Layout);
