import React from 'react';
import {Carousel} from "react-bootstrap";
import {Link} from 'react-router-dom';

const Pack = () => {

    const createCarouselPack = (slug) => {

        let items = [];

        for (let i = 0; i < 3; i++) {

            items.push(
                <Carousel.Item key={slug+"Item" + i}>
                    <div className={"carousel_pack_"+slug+"_item"} id={"carousel_pack_"+slug+"_item" + (i + 1)}>
                        <div className={"picture"}/>
                    </div>
                </Carousel.Item>
            );
        }

        return (
            <div id={"pack_"+slug+"_carousel_wrapper"} className={"pack_carousel_wrapper"}>
                <Carousel controls={true} interval={2000} indicators={false}>
                    {items}
                </Carousel>
            </div>
        );
    };

    return (
        <div id={"packStrate1"} className={"strate"}>
            <div className={"pack_wrapper"} id={"pack_loisir_wrapper"}>
                <div className={"picture"}/>
                <div className={"title"}>Cet été, célébrez votre diplôme</div>
                <div className={"subtitle"}>avec de l’action, de l’aventure,<br className={"d-lg-none"}/> de la découverte,<br className={"d-lg-block d-none"}/> des cris de joie<br className={"d-lg-none"}/> ou de frayeurs ! </div>
                { createCarouselPack("loisir") }
                <div className={"description"}>
                    Parcs d’attraction, bowling, visites de <br className={"d-lg-none"}/>
                    musée, tirs à l’arc, bubble bump, <br/>
                    accrobranches et plein d’autres encore : <br/>
                    <span>venez profiter de 8 activités culturelles, sportives ou de loisirs,</span><br className={"d-lg-block d-none"}/> offertes sur la <br className={"d-lg-none"}/>
                    plateforme Rewards For All !
                </div>
                <div className={"logo"}/>
            </div>
            <div className={"pack_wrapper"} id={"pack_celebration_wrapper"}>
                <div className={"picture"}/>
                <div className={"title"}>Chantez, dansez, mangez !</div>
                <div className={"subtitle"}>Vous allez bouger pour fêter <br className={"d-lg-none"}/>dignement <br className={"d-lg-block d-none"}/>votre diplôme. </div>
                { createCarouselPack("celebration") }
                <div className={"description"}>
                    A la maison, en famille ou avec vos amis, <br className={"d-lg-none"}/>
                    profitez de 3 offres digitales : <br/>
                    Dansez au rythme de la playlist de 50 <br className={"d-lg-none"}/>
                    titres Deezer x BNP Paribas, partagez une <br className={"d-lg-none"}/>
                    pizza grâce <br className={"d-lg-block d-none"}/>au bon Uber Eats de 15€ et <br className={"d-lg-none"}/>
                    immortalisez ce moment avec 10 tirages <br className={"d-lg-none"}/>
                    photos Cheerz !

                    <div className={"plus"}/>
                    <span>Choisissez <br className={"d-lg-none"}/>une autre offre parmi :</span>
                    <ul>
                        <li>1 mois d’abonnement karaoké <br className={"d-lg-none"}/>avec Yokee</li>
                        <li>1 mois de concert Qello</li>
                        <li>1 VOD au choix RakutenTV</li>
                        <li>1 escape game en ligne Escape Hunt</li>
                        <li>1 mois d’abonnement à une playlist <br className={"d-lg-none"}/>Stingray Musique</li>
                    </ul>
                </div>
                <div className={"logo"}/>
            </div>
        </div>
    );
};



    export default Pack;
