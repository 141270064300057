import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';

import CustomFieldText from '../form/CustomFieldText';

import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';

import ReCAPTCHA from "react-google-recaptcha";
import CustomComboBox from "../form/CustomComboBox";

import Contact from "./contact";

const JeParticipeForm = (props) => {

    const {register, handleSubmit, errors, clearError, setError, setValue} = useForm({validateCriteriaMode: "all"});
    const recaptchaRef = useRef(null);
    const submitRef = useRef(null);

    //form validation
    const onSubmit = data => {

        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/submit_form.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    props.setDotation(data.pack)
                    props.setShowMerci(true);

                    window.mixpanelhandler.track("Participation Form Submitted",{
                        "Result count" : 1
                    });

                } else {
                    //setFormError(json.error);
                    alert(json.error);

                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    };

    return (
        <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

            <div id={"form_content_wrapper"}>

                <CustomFieldText name={"code"} label={"Code d’activation*"}
                                 register={register({required: true})}
                                 error={[errors.code && errors.code.type === "required" && Helper.EmptyErrorMessages.code_unique]}/>

                <CustomFieldText name="mail" label="Adresse e-mail*"
                                 register={
                                     register({
                                         required: true,
                                         pattern: Pattern.emailPattern,
                                         validate: {
                                             domain: (value) => Helper.checkMailDomain(value)
                                             /*confirm : checkMailConfirm*/
                                         }
                                     })}
                                 error={[
                                     errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                     errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                     errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                     errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                 ]}/>

                <CustomFieldText name="mail_confirm" label="Confirmation de l'adresse e-mail*"
                                 register={
                                     register({
                                         required: true,
                                         pattern: Pattern.emailPattern,
                                         validate: {
                                             domain: (value) => Helper.checkMailDomain(value),
                                             confirm: Helper.checkMailConfirm
                                         }
                                     })}
                                 error={[
                                     errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                     errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                     errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                     errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                 ]}/>

                <CustomComboBox name="pack" id="pack" label={"Pack sélectionné (choix définitif)*"}
                                placeholder={"Sélectionner"}
                                options={[
                                    [
                                        "Pack Loisirs",
                                        "loisir"
                                    ],
                                    [
                                        "Pack Célébration",
                                        "celebration"
                                    ]
                                ]}
                                register={register({
                                    required: {
                                        value: true,
                                        message: Helper.EmptyErrorMessages.pack
                                    }
                                })}
                                error={errors.pack && errors.pack.message}/>


                <div className={"optin_container"}>

                    <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_reglement" id="optin_reglement" value="1"
                               ref={register({required: true})}/>

                        <label htmlFor="optin_reglement">
                            Je reconnais avoir pris connaissance des <a href="/file/Termes%20et%20conditions.pdf"
                                                                        target={"_blank"}>Termes et Conditions</a> de
                            l’offre et en accepter les termes.<span>*</span>
                        </label>
                        <div
                            className="error text-center">{errors.optin_reglement && Helper.EmptyErrorMessages.optin_reglement}</div>
                    </div>

                    <div className={"mandatory"}>
                        *Tous les champs sont obligatoires. Toute demande incomplète ne pourra être validée et traitée.
                        Le choix du pack est définitif et ne peut être modifié.
                    </div>

                    <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_tlc" id="optin_tlc" value="1" ref={register()}/>

                        <label htmlFor="optin_tlc">
                            Oui, j’accepte d’être contacté(e) par TLC Marketing dans le cadre de la présente offre, pour
                            recueillir mon avis sur la promotion commerciale ainsi qu’à des fins d’enquêtes de
                            satisfaction,
                            d’élaboration de statistiques, d’organisation de jeux concours ou d’envoi d’informations par
                            e&#45;mail.
                        </label>
                    </div>

                </div>
                <div id={"rgpd_text_wrapper"}>
                    <p>
                        Conformément à la loi Informatique et libertés du 6 janvier 1978 dans sa dernière version en
                        vigueur
                        ainsi que le Règlement UE 2016/679 du 27 avril 2016 publié au Journal Officiel de l’Union
                        Européenne
                        le 4 mai 2016 relatif à la protection des personnes physiques à l'égard du traitement des
                        données à
                        caractère personnel et à la libre circulation de ces données, les informations recueillies sont
                        destinées à TLC Marketing en sa qualité de responsable de traitement.
                        TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la
                        confidentialité des données, conformément aux dispositions légales précitées. Vos informations
                        seront conservées jusqu’au 28/02/2027, au plus tard conformément à la réglementation si vous
                        avez
                        coché une case opt-in du formulaire de participation. Autrement, vos données seront supprimées à
                        partir du 30/04/2024. Vous bénéficiez d’un droit d’accès, de rectification, d’opposition, de
                        limitation, d’effacement, de portabilité et d’information concernant les données personnelles
                        vous
                        concernant. Afin d’exercer ces droits, nous vous remercions de bien vouloir adresser votre
                        demande
                        auprès de TLC Marketing France par e-mail à <a
                        href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"}>francedata@tlcrewards.com</a> ou
                        par courrier à l’adresse suivante : TLC Marketing France – Mes données personnelles, 92 avenue
                        de
                        Wagram, 75017 Paris.
                    </p>
                </div>

            </div>

            {/* recaptcha */}
            <div className="text-center" id={"recaptcha_wrapper"}>
                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b"
                           ref={recaptchaRef}/>
                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
            </div>

            {/* server error */}
            <div className="text-center">
                <div className={"error text-center mb-3"} id={"global_error"}>
                    {/*error*/}
                </div>
                <button className={"cta yellow_pink big"} id={"submit_cta"} ref={submitRef}>Je valide</button>
            </div>


        </Form>
    );
};

const JeParticipeMerci = (props) => {

    return (
        <>
            <div id={"bravoStrate1"} className={"strate"}>

                <h1>Merci</h1>

                <div className={"subtitle"}>
                    Votre participation<br/>
                    a bien été prise en compte.
                </div>

                {props.dotation === "loisir" &&
                    <p>
                        Vous recevrez dans quelques instants <br className={"d-lg-none"}/>
                        votre code <b>Pack Loisirs</b> par e-mail. <br/>
                        Ce code Pack Loisirs vous permettra <br className={"d-lg-none"}/>
                        d’accéder à la liste <br className={"d-none d-lg-block"}/>des partenaires et <br
                        className={"d-lg-none"}/>
                        de choisir les 8 activités qui vous font <br className={"d-lg-none"}/>
                        plaisir !
                    </p>
                }

                {props.dotation === "celebration" &&

                     <p>
                        Vous recevrez dans quelques instants <br className={"d-lg-none"}/>
                        votre code <b>Pack Célébration</b> par e-mail. <br/>
                        Ce code Pack Loisirs vous permettra <br className={"d-lg-none"}/>
                        d’accéder <br className={"d-none d-lg-block"}/>aux offres digitales et <br className={"d-lg-none"}/>
                        de sélectionner vos 4 offres  <br className={"d-lg-none"}/>
                        préférées !
                    </p>
                }

                <div className={"merci_title2"}/>

                <p>
                    <b>Vous ne recevez <br className={"d-lg-none"}/>pas votre e-mail ?</b><br/>
                    Pensez à vérifier vos courriers <br className={"d-lg-none"}/>
                    indésirables <br className={"d-none d-lg-block"}/>pour être sûr qu’il ne <br className={"d-lg-none"}/>
                    s’y trouve pas !
                </p>

            </div>
            <Contact pack={props.dotation}/>
        </>
    );
};

const JeParticipe = () => {


    const [showMerci, setShowMerci] = useState(false);
    const [dotation, setDotation] = useState("");

    return (
        <>
            {!showMerci &&
                <div id={"participeStrate1"} className={"strate header_global1 first last"}>
                    <h1>Je participe</h1>

                    <div className={"subtitle"}>
                        <span>Complétez le formulaire</span><br/>
                        pour recevoir le pack de votre choix !
                    </div>
                    <h3>Vous hésitez entre<br className={"d-none d-lg-block"}/> le Pack Loisirs <br className={"d-lg-none"}/>et le Pack Célébration ?</h3>
                    <p>
                        Cliquez <Link to={"/les-packs"} className={"underline"}>ici</Link> pour avoir plus d’informations <br/>
                        sur les différents packs.
                    </p>

                    <JeParticipeForm setShowMerci={setShowMerci} setDotation={setDotation} mixpanel={window.mixpanelhandler}/>
                </div>
            }

            {showMerci && <JeParticipeMerci dotation={dotation}/>}

        </>
    );
};

export default JeParticipe;
