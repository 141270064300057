import React from 'react';

const MentionsLegales = () => {
  return (
    <>
      <div className={"strate"} id={"mlStrate1"}>

        <h1>Informations légales</h1>

        <div id={"ml_wrapper"}>
          <div className="block">
            <h4>Ce site est édité et hébergé par :</h4>

            <p>
              TLC Marketing<br/>
              92 avenue de Wagram – 75017 Paris<br/>
              N° SIREN : 491 414 306<br/>
              N° SIRET : 491 414 306 00036<br/>
              APE : 7311Z<br/>
              RCS : Paris B 491 414 306<br/>
              SARL au capital social de : 150 000€<br/>
              Numéro de TVA intracommunautaire : FR 70 491 414 306<br/>
              Adresse de courrier électronique : <br className={"d-lg-none"}/>
              <a href="mailto:bonjour@tlcmarketing.com">bonjour@tlcmarketing.com</a>
            </p>
          </div>
          <div className="block">
            <h4>Pour le compte de :</h4>
            <p>
              BNP Paribas<br/>
              16 boulevard des Italiens – 75009 Paris<br/>
              N° SIREN : 662 042 449<br/>
              N° SIRET : 662 042 449<br/>
              RCS : Paris 662 042 449<br/>
              Société anonyme au capital de 2 468 663 292€<br/>
              Numéro de TVA intracommunautaire : FR 76 662 042 449
            </p>
          </div>

          <div className={"smallBlock"}>
            La conception, le design et le développement du site ont été effectués par TLC Marketing et Cloud Media.
          </div>

        </div>
      </div>
    </>

  );
};

export default MentionsLegales;


