//message d'erreur
export let EmptyErrorMessages = {
  civilite       : "Veuillez sélectionner votre civilité",
  nom            : "Veuillez saisir votre nom de famille",
  prenom         : "Veuillez saisir votre prénom",
  date_naissance : "Veuillez saisir votre date de naissance",
  mail           : "Veuillez saisir votre adresse e-mail",
  mail_confirm   : "Veuillez confirmer votre adresse e-mail",
  adresse        : "Veuillez saisir votre adresse postale",
  cp             : "Veuillez saisir votre code postal",
  ville          : "Veuillez saisir votre ville",
  optin_reglement: "Veuillez lire et accepter les Termes et Conditions pour continuer",
  optin_consent : "Veuillez cocher cette case pour continuer",
  recaptcha      : "Veuillez cocher cette case pour continuer",
  code_unique    : "Veuillez saisir le code d’activation adressé par BNP Paribas",
  pack           : "Veuillez sélectionner un pack parmi les 2 proposés",

  generic: "Veuillez compléter cette information pour continuer"
};

export let InvalidErrorMessages = {
  nom               : "Veuillez saisir un nom correct",
  prenom            : "Veuillez saisir un prénom correct",
  date_naissance_age: "Vous devez être majeur(e) pour participer à l’opération",
  adresse           : "Veuillez saisir une adresse valide",
  cp                : "Veuillez saisir un code postal de 5 caractères sans espace",
  ville             : "Veuillez saisir une ville valide",
  mail              : "Veuillez saisir une adresse e-mail correcte",
  mail_dif          : "Veuillez saisir la même adresse e-mail que précédemment",
  mail_domain       : "Temporary email adress are not allowed",
  pack              : "Veuillez selectionner votre pack"
};

//--------------------
//case 'tous' cochée
//--------------------
export function checkAllRgpdBox(event) {
  let parent = event.target.closest('.rgpd_checkbox_wrapper');
  let childs = parent.querySelectorAll('input[type="checkbox"]:not(.checkbox_all)');
  let all    = parent.querySelector('.checkbox_all');

  childs.forEach(child => {
    child.checked = all.checked;
  });

}

//--------------------
//décochage de la case 'tous' si une autre case est décochée
//--------------------
export function uncheckRgpdAllBox(event) {

  let isCheckedAll = true;
  let parent       = event.target.closest('.rgpd_checkbox_wrapper');
  let childs       = parent.querySelectorAll('input[type="checkbox"]:not(.checkbox_all)');
  let all          = parent.querySelector('.checkbox_all');

  childs.forEach(child => {
    if (!child.checked)
      isCheckedAll = false;
  });

  all.checked = isCheckedAll;
}

//------------------------
//	on change field mail
//------------------------
export function checkMailConfirm() {

  let mail         = document.getElementById("mail").value;
  let mail_confirm = document.getElementById("mail_confirm").value;

  //check de la correspondance des deux emails
  if (mail.length !== 0 && mail_confirm.length !== 0 && mail !== mail_confirm)
    return false;

  return true;
}

export function checkMailDomain(value) {

  let domain              = value.substring(value.indexOf('@') + 1);
  let forbiddenMailDomain = [
    'yopmail.com',
    'clipmail.eu',
    'clrmail.com',
    'get2mail.fr',
    'mail-temporaire.fr',
    'spamfree24.org',
    'jetable.org',
    'lala.fr'];

  //check de l'extension du mail
  return (!forbiddenMailDomain.includes(domain));
}

//------------------------
//	controle age participant
//------------------------
export function checkBirthDate() {

  let day   = document.getElementById("jour_naissance").value;
  let month = document.getElementById("mois_naissance").value;
  let year  = document.getElementById("annee_naissance").value;

  if (day.length !== 0 && month.length !== 0 && year.length !== 0) {

    let birthday = new Date(year, month - 1, day);

    let ageDifMs  = Date.now() - birthday.getTime();
    let ageDate   = new Date(ageDifMs);
    let diffYears = Math.abs(ageDate.getUTCFullYear() - 1970);

    console.log(diffYears);

    if (diffYears < 18) {
      return false;
    }
  }

  return true;
}

//------------------------
//	controle du numéro de tel
//------------------------
export function checkTelNumber(value) {

  let valid = true;

  value = value.replace('+33', '0');

  if (!/^[0-9 ]+$/.test(value))
    valid = false;

  if (value.charAt(0) !== '0')
    valid = false;

  if (value === '0000000000')
    valid = false;

  if (value.length !== 10)
    valid = false;

  return valid;
}

//------------------------
//	Password
//------------------------
export function checkPasswordStrenght(password) {

  /*console.log(/\d/.test(password));
  console.log(password.length);*/

  if (password.length == 0)
    return true;

  return /\d/.test(password) && (password.length >= 6);
}

export function checkPasswordConfirm() {
  let password         = document.getElementById("mot_de_passe").value;
  let password_confirm = document.getElementById("mot_de_passe_confirm").value;

  //check de la correspondance des deux emails
  if (password.length !== 0 && password_confirm.length !== 0 && password !== password_confirm)
    return false;

  return true;
}

//------------------------
//	controle du fichier avant upload
//------------------------
export function checkIsUploadable(fileToUpload) {

  //check des extensions
  let validExtensions = [
    'jpg',
    'jpeg',
    'png',
    'pdf'];
  let extension       = fileToUpload.name.substr(fileToUpload.name.lastIndexOf(".") + 1).toLowerCase();
  let file_size       = fileToUpload.size;
  let max_size        = 5 * 1024 * 1024;

  if (!validExtensions.includes(extension) || file_size > max_size) {
    return false;
  }

  return true;
}