import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const Home = () => {

    const [packCount,setPackCount] = useState(10000);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/get_count.php',
            {
                method: 'POST'
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    setPackCount(json.count);
                }
            });
    }, []);

    return (
        <div id={"homeStrate1"} className={"strate"}>
            <h1>Bravo pour votre diplôme !</h1>
            <h2>Comment voulez-vous<br/>le fêter ?</h2>

            <div className={"home_pack_wrapper"}>
                <div className={"home_pack_item"} id={"home_pack_loisir"}>
                    <div className={"picture"}></div>
                    <h3 className={"title"}>Cet été rime avec activités !</h3>
                    <p className={"description"}>Du fun, de l’action et de l’aventure :<br className={"d-lg-none"}/> vivez cet été à fond<br className={"d-none d-lg-block"}/> grâce à 8 activités<br className={"d-lg-none"}/> au choix offertes*.</p>
                    <div className={"logo"}></div>
                </div>
                <div className={"home_pack_item"} id={"home_pack_celebration"}>
                    <div className={"picture"}></div>
                    <h3 className={"title"}>Cet été sera inoubliable !</h3>
                    <p className={"description"}>De la musique, des pizzas et des<br className={"d-lg-none"}/> photos : immortalisez<br className={"d-none d-lg-block"}/> ce moment<br className={"d-lg-none"}/> grâce aux 4 offres digitales offertes*.</p>
                    <div className={"logo"}></div>
                </div>
            </div>

            <div className={"cta_wrapper"}>
                <Link to={"/les-packs"} className={"cta small yellow"} onClick={() => window.mixpanelhandler.track("Participation_Homepage",{"Je decouvre_Result count" : 1})}>Je découvre</Link>
                <Link to={"/je-participe"} className={"cta big yellow_pink"} onClick={() => window.mixpanelhandler.track("Participation_Homepage",{"Je participe_Result count" : 1})}>Je choisis</Link>
            </div>

            <div className={"cpt_wrapper"}>
                <div>Il reste</div>
                <div className={"cpt"}>
                    <span>{packCount}</span>
                    <span>{packCount}</span>
                </div>
                <div>packs à choisir !</div>
            </div>

            <div className={"disclaimer"}>
                *Offre réservée aux clients et nouveaux clients BNP Paribas de France Métropolitaine (Corse incluse) ayant obtenu le Diplôme National du Brevet ou le Baccalauréat en 2023. Offre limitée à 10 000 participations. Voir <a href={"/file/Termes%20et%20conditions.pdf"} target={"_blank"}>Termes et Conditions</a>.
            </div>
        </div>
    );
};


export default Home;
